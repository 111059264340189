












































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { formatDateTimeToLocalDateTime } from '@/utils/dateUtils'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private listViewKey = 0
  private loading = false

  private showMoreDialog = false
  private fullText = ''
  private fullTextTitle = ''

  private headers = []
  private topActions = []

  created() {
    this.setHeaders()
    this.setTopActions()
  }

  private get url() {
    return '/v4/sysop/api-request-logs'
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Time of call',
        value: 'timeOfCall',
        filter: { disable: true },
      },
      {
        text: 'c:api-request-log:Total request time',
        value: 'totalRequestTime',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Site ID',
        value: 'siteId',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Api key',
        value: 'apiKey',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:IP address',
        value: 'ipAddress',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Query strings',
        value: 'queryStrings',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Route parameters',
        value: 'routeParameters',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Body parameters',
        value: 'bodyParameters',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Method',
        value: 'method',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Raw route',
        value: 'rawRoute',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Success',
        value: 'success',
        filter: { disable: false },
      },
      {
        text: 'c:api-request-log:Error message',
        value: 'errorMessage',
        filter: { disable: false },
      },
    ]
  }

  private setTopActions() {
    this.topActions = [
      {
        id: 'show',
        template: 'show',
        label: 'c:api-request-log:Statistics',
        route: () => {
          return {
            name: 'Sysop/Api/Request/Logs/Statistics',
          }
        },
      },
    ]
  }

  private formatDate(date: string) {
    return formatDateTimeToLocalDateTime(date)
  }

  private showFullText(text: string, title: string) {
    this.fullText = text
    this.fullTextTitle = this.$t(`c:api-request-log:${title}`)
    this.showMoreDialog = true
  }

  private getItemClass(item: any) {
    return item.success ? '' : 'row-error'
  }
}
