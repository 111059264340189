var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',[_c('page-tab',{attrs:{"title":"c:api-request-log:Api Request Logs"}}),(_vm.loading)?[_c('v-row',{staticClass:"my-6",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100"}})],1)]:[_c('list-view',{key:_vm.listViewKey,attrs:{"headers":_vm.headers,"url":_vm.url,"loading":_vm.loading,"top-actions":_vm.topActions,"items-per-page":100,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250] },"item-class":_vm.getItemClass},scopedSlots:_vm._u([{key:"item.timeOfCall",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.timeOfCall)))]}},{key:"item.totalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalRequestTime)+"ms ("+_vm._s(parseFloat(item.totalRequestTime / 1000).toFixed(2))+"s) ")]}},{key:"item.queryStrings",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.queryStrings.length > 101)?_c('span',[_vm._v(" "+_vm._s(item.queryStrings.slice(0, 101))+"... "),_c('span',{staticClass:"light-blue--text text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.showFullText(item.queryStrings, _vm.$t('c:api-log-request:Query strings'))}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.queryStrings)+" ")])])]}},{key:"item.routeParameters",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.routeParameters.length > 101)?_c('span',[_vm._v(" "+_vm._s(item.routeParameters.slice(0, 101))+"... "),_c('span',{staticClass:"light-blue--text text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.showFullText(item.routeParameters, _vm.$t('c:api-log-request:Route parameters'))}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.routeParameters)+" ")])])]}},{key:"item.bodyParameters",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.bodyParameters.length > 101)?_c('span',[_vm._v(" "+_vm._s(item.bodyParameters.slice(0, 101))+"... "),_c('span',{staticClass:"light-blue--text text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.showFullText(item.bodyParameters, _vm.$t('c:api-log-request:Body parameters'))}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.bodyParameters)+" ")])])]}},{key:"item.errorMessage",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.errorMessage.length > 101)?_c('span',[_vm._v(" "+_vm._s(item.errorMessage.slice(0, 101))+"... "),_c('span',{staticClass:"light-blue--text text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.showFullText(item.errorMessage, _vm.$t('c:api-log-request:Error message'))}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])]):_c('span',[_vm._v(" "+_vm._s(item.errorMessage)+" ")])])]}}])})]],2),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showMoreDialog),callback:function ($$v) {_vm.showMoreDialog=$$v},expression:"showMoreDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.fullTextTitle)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showMoreDialog = false}}},[_c('v-icon',[_vm._v("fa fa-times")])],1)],1),_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.fullText))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }